import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import styles from './CustomerLogos.module.scss';
import Fade from 'react-reveal/Fade';
import { useBreakpoint } from '../../hooks/useBreakpoint.hook';

SwiperCore.use([Autoplay]);

interface IProps {
  logos: { imageSrc: string; alt: string; maxWidth?: string }[];
}

const CustomerLogos: React.FC<IProps> = ({ logos }) => {
  const bp = useBreakpoint();

  if (!logos) {
    return null;
  }

  if (bp === 'sm') {
    return (
      <Swiper
        className={styles.swiper}
        spaceBetween={20}
        slidesPerView={1}
        speed={900}
        autoplay={{ delay: 2000 }}
        loop
      >
        {logos.map((l, i) => (
          <SwiperSlide key={`logo_${i}`} className={styles.logo}>
            <img
              className={styles.logo__image}
              src={l.imageSrc}
              style={{ maxWidth: l.maxWidth ?? '180px' }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }

  return (
    <Fade bottom delay={200} distance={'5rem'} duration={600} origin={'bottom'}>
      <div className={styles.container}>
        {logos.map((l, i) => (
          <div key={`logo_${i}`} className={styles.logo__container}>
            <img
              className={styles.logo__image}
              src={l.imageSrc}
              style={{ maxWidth: l.maxWidth ?? '180px' }}
            />
          </div>
        ))}
      </div>
    </Fade>
  );
};

export default CustomerLogos;
