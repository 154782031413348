import React, { ReactNode } from 'react';
import styles from './ContentSection.module.scss';

interface IProps {
  children: ReactNode;
  id?: string;
  className?: string;
}

const ContentSection: React.FC<IProps> = ({ children, id, className = '' }) => {
  return (
    <section className={`${styles.wrapper} ${className}`}>
      <div className={`welcome content__container`} id={id}>
        {children}
      </div>
    </section>
  );
};

export default ContentSection;
