import React, { ReactNode } from 'react';
import Fade from 'react-reveal/Fade';

// import styles from './TextAndImage.module.scss';

interface IProps {
  children?: ReactNode;
  image?: string;
  alt?: string;
}

const TextAndImage: React.FC<IProps> = ({ children = undefined, image = undefined, alt = '' }) => {
  return (
    <>
      <Fade bottom delay={200} distance={'5rem'} duration={600} origin={'bottom'}>
        <div className="content reveal-bottom">
          <div className="content__cell content__cell--6-12 content__cell--centered-small">
            {children}
          </div>
          <div className="content__cell content__cell--6-12 content__cell--hide-small">
            {image && (
              <img className="image__portrait image__portrait--right" src={image} alt={alt} />
            )}
          </div>
        </div>
      </Fade>
    </>
  );
};

export default TextAndImage;
