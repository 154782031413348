import React from 'react';
import Image from 'gatsby-image';

import styles from './Bio.module.scss';

interface IProps {
  author: string;
  avatar: any;
  bio?: string;
}

const Bio: React.FC<IProps> = ({ author, avatar, bio }) => {
  return (
    <div className={styles.container}>
      <div className={styles.image__wrapper}>
        <Image
          fixed={avatar.childImageSharp.fixed}
          alt={author}
          className={styles.image__container}
          fadeIn={false}
        />
      </div>
      <div className={styles.bio__wrapper}>
        <p className={styles.container__text}>
          Written by <strong>{author}</strong>
        </p>
        {bio && <p>{bio}</p>}
      </div>
    </div>
  );
};

export default Bio;
