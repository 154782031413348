import React, { useState } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Link } from 'gatsby';

import Logo from '../../../../../egonoid.com/static/assets/img/logos/egonoid-logo.svg';
import MenuItem from '../../models/MenuItem.model';

import styles from './Navigation.module.scss';
import { LanguageSelect } from '.';

interface IProps {
  showLogo?: boolean;
  mainMenu?: MenuItem[];
  language?: string;
}

const Navigation: React.FC<IProps> = ({ showLogo = true, mainMenu = [], language = 'en' }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleOnClickMenu = (showMenu: boolean) => {
    return (event: React.MouseEvent) => {
      setShowMobileMenu(showMenu);
      return event.preventDefault();
    };
  };

  const alternateLanguage = (language: string) => {
    switch (language) {
      case 'de':
        return { language: 'en', link: '/' };
      default:
        return { language: 'de', link: '/de' };
    }
  };

  const Menu: React.FC = () => {
    return (
      <>
        {mainMenu.length > 0 && (
          <ul className={styles.navigation__list}>
            {mainMenu.map((item: MenuItem, index) => (
              <li key={index} onClick={() => setShowMobileMenu(false)}>
                <AnchorLink
                  to={item.link}
                  title={item.name}
                  className={styles.navigation__list__link}
                >
                  {item.name}
                </AnchorLink>
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };

  return (
    <>
      <div
        id="nav-overlay"
        className={styles.navigation__overlay}
        style={showMobileMenu ? { display: 'block' } : { display: 'none' }}
      >
        <div className={styles.navigation__overlay__container}>
          <div className={styles.navigation__overlay__content}>
            <div className={`${styles['navigation__overlay__nav-list']}`}>
              <Logo className={styles.navigation__overlay__logo} />
              <Menu />
            </div>
          </div>
        </div>

        <button
          className={`${styles.navbar__button} ${styles['navbar__button--close']} close-button`}
          type="button"
          onClick={handleOnClickMenu(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        className={`${styles.navbar__container} ${styles['sticky-container']} ${styles['is-stuck']}`}
      >
        <div className={styles.navbar}>
          <div className="grid-container">
            <div className="grid-x">
              <div className={styles.navbar__cell}>
                <button
                  className={`${styles.navbar__button} menu-icon`}
                  type="button"
                  onClick={handleOnClickMenu(true)}
                />

                <div
                  className={styles.navbar__logo__container}
                  style={showLogo ? { opacity: '1' } : { opacity: '0' }}
                >
                  <Link to="/">
                    <Logo className={styles.navbar__logo} />
                  </Link>
                </div>
                <div className={styles['navbar__language--mobile']}>
                  <LanguageSelect {...alternateLanguage(language)} />
                </div>

                <div className={styles.navigation__list__container}>
                  <Menu />
                  <div className={styles.navbar__language}>
                    <LanguageSelect {...alternateLanguage(language)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
