import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import MenuItem from '../../models/MenuItem.model';

// import styles from './Footer.module.scss';

interface IProps {
  menu?: MenuItem[];
}

const Footer: React.FC<IProps> = ({ menu }) => {
  return (
    <>
      <footer className="footer__container__wrapper">
        <div className="footer__container">
          <div className="footer">
            <div className="footer__cell--6-12">
              <span>&copy; {new Date().getFullYear()} egonoid GmbH. All rights reserved.</span>
            </div>
            <div className="footer__cell--6-12">
              {menu && menu.length > 0 && (
                <ul className="footer__list">
                  {menu.map((item: MenuItem, index: number) => (
                    <li key={index} className="footer__list__item">
                      <AnchorLink to={item.link} title={item.name} className="link--primary">
                        {item.name}
                      </AnchorLink>
                    </li>
                  ))}
                </ul>
              )}
              {/* <ul className="footer__list">
                <li className="footer__list__item">
                  <a className="link--primary" href="/#contact" data-smooth-scroll data-offset="54">
                    Contact
                  </a>
                </li>
                <li className="footer__list__item">
                  <a className="link--primary" href="/imprint/">
                    Imprint
                  </a>
                </li>
                <li className="footer__list__item">
                  <a className="link--primary" href="/privacy/">
                    Privacy
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
