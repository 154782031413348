import React, { ReactNode } from 'react';
import Fade from 'react-reveal/Fade';

// import styles from './TextAndIcon.module.scss';

interface IProps {
  children: ReactNode;
  icon?: ReactNode;
}

const TextAndIcon: React.FC<IProps> = ({ children, icon = undefined }) => {
  return (
    <>
      <Fade bottom delay={200} distance={'5rem'} duration={600} origin={'bottom'}>
        <div className="content__cell content__cell--6-12">
          <div className="content reveal-bottom">
            <div className="content__cell--3-12">{icon && icon}</div>
            <div className="content__cell--9-12">{children}</div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default TextAndIcon;
