import React, { ReactNode } from 'react';
import Fade from 'react-reveal/Fade';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import Logo from '../../../../../egonoid.com/static/assets/img/logos/egonoid-logo.svg';
import Arrow from '../../../../../egonoid.com/static/assets/img/icons/arrow.svg';

import styles from './Start.module.scss';

interface IProps {
  children?: ReactNode;
  link?: string;
}

const Start: React.FC<IProps> = ({ children = undefined, link = undefined }) => {
  return (
    <>
      <div className={styles.start} id="start">
        <div className={styles['start__grid-container']}>
          <div className={styles['start__grid-y']}>
            <div className={styles['start__offset__cell-y']}></div>
            <div className={styles.start__content__mid}>
              <div className={styles['start__content__grid-x']}>
                <div className={styles['start__content__cell-x']}>
                  <Fade left delay={150} distance={'30rem'} duration={1300}>
                    <div>
                      <Logo className={styles.start__content__logo} />
                    </div>
                  </Fade>
                  <h2 className={styles.start__content__slogan}>
                    <Fade right delay={1200} distance={'3rem'} duration={200}>
                      <span>agility.</span>
                    </Fade>
                    <Fade right delay={1500} distance={'3rem'} duration={200}>
                      <span className="reveal-slogan-2"> technology.</span>
                    </Fade>
                    <Fade right delay={1800} distance={'3rem'} duration={200}>
                      <span className="reveal-slogan-3"> results.</span>
                    </Fade>
                  </h2>
                  <Fade bottom delay={2000} distance={'2rem'} duration={1300}>
                    <div className={styles.start__content__contact}>{children}</div>
                  </Fade>
                </div>
              </div>
            </div>
            {link && (
              <div className={styles.start__content__bottom}>
                <AnchorLink to={link} title="Welcome" className="arrow-bounce">
                  <Arrow className={styles['start__content__arrow-icon']} />
                </AnchorLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Start;
