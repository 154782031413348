import React from 'react';
import Fade from 'react-reveal/Fade';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGoogleMap from 'react-google-map';

import styles from './Map.module.scss';

const newlineRegex = /(\r\n|\r|\n)/g;

const nl2br = (value: string) => {
  if (typeof value !== 'string') {
    return value;
  }

  return value.split(newlineRegex).map(function (line, index) {
    if (line.match(newlineRegex)) {
      return React.createElement('br', { key: index });
    }
    return line;
  });
};

interface IProps {
  address?: string;
  email?: string;
  website?: string;
  zoomLevel?: number;
  language?: string;
}

const Map: React.FC<IProps> = ({
  address = undefined,
  email = undefined,
  website = undefined,
  zoomLevel = 12,
  language = 'en',
}) => {
  return (
    <>
      <Fade bottom delay={200} distance={'5rem'} duration={600} origin={'bottom'}>
        <div className="content reveal-bottom">
          <div className="content__cell content__cell--12-12">
            <div className={styles.contact__container}>
              <div className={`${styles.contact__list__container} reveal-left-long-delay`}>
                <ul className={styles.contact__list}>
                  {address && (
                    <li
                      className={`${styles.contact__list__item} ${styles.contact__icon} ${styles['contact__icon--address']}`}
                    >
                      {nl2br(address)}
                    </li>
                  )}
                  {email && (
                    <li
                      className={`${styles.contact__list__item} ${styles.contact__icon} ${styles['contact__icon--mail']}`}
                    >
                      <a className="link--primary" href={`mailto:${email}`}>
                        {email}
                      </a>
                    </li>
                  )}
                  {website && (
                    <li
                      className={`${styles.contact__list__item} ${styles.contact__icon} ${styles['contact__icon--website']}`}
                    >
                      <a
                        className="link--primary"
                        href={`https://${website}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {website}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="reveal-right-long-delay">
                <ReactGoogleMapLoader
                  params={{
                    key: 'AIzaSyBlAmWgBxnHfXjA0hvq9XYOeBxWl21fMM4',
                    libraries: 'places,geometry', // To request multiple libraries, separate them with a comma
                    language: language,
                  }}
                  render={(googleMaps) =>
                    googleMaps && (
                      <div className={styles.contact__map}>
                        <ReactGoogleMap
                          googleMaps={googleMaps}
                          coordinates={[
                            {
                              title: 'egonoid GmbH',
                              position: {
                                lat: 50.949183,
                                lng: 6.945241,
                              },
                              onLoaded: (googleMaps, map, marker) => {
                                // Set Marker animation
                                marker.setAnimation(googleMaps.Animation.BOUNCE);
                              },
                            },
                          ]}
                          center={{ lat: 50.949183, lng: 6.945241 }}
                          zoom={zoomLevel}
                          disableDefaultUI={true}
                        />
                      </div>
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Map;
