import React from 'react';
import PropTypes from 'prop-types';

import styles from './Hero.module.scss';

const Hero = ({ image, title, subtitle }) => (
  <div
    className={styles['hero__body']}
    style={{
      background: `url(${image}) 50% no-repeat`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <div className={styles['hero__text']}>
      <h1>{title}</h1>
      <h5>{subtitle}</h5>
    </div>
  </div>
);

Hero.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Hero.defaultProps = {
  image: ``,
  title: ``,
  sub: ``,
};

export default Hero;
