import { useWindowDimensions } from './useWindowDimensions.hook';

export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const breakpoints: { name: Breakpoint; minWidth: number }[] = [
  {
    name: 'sm',
    minWidth: 0,
  },
  {
    name: 'md',
    minWidth: 640,
  },
  {
    name: 'lg',
    minWidth: 1024,
  },
  {
    name: 'xl',
    minWidth: 1200,
  },
  {
    name: 'xxl',
    minWidth: 1440,
  },
];

export const useBreakpoint = (): Breakpoint => {
  const { width } = useWindowDimensions();

  for (let i = 0; i < breakpoints.length; i++) {
    if (width >= breakpoints[i].minWidth) {
      if (!breakpoints[i + 1] || width < breakpoints[i + 1].minWidth) {
        return breakpoints[i].name;
      }
    }
  }

  throw Error('Could not identify breakpoint.');
};
