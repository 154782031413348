import React, { ReactNode } from 'react';
import Fade from 'react-reveal/Fade';

// import styles from './ContentText.module.scss';

interface IProps {
  children: ReactNode;
}

const ContentText: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <Fade bottom delay={200} distance={'5rem'} duration={600} origin={'bottom'}>
        <div className="content reveal-bottom">
          <div className="content__cell content__cell--centered-text">{children}</div>
        </div>
      </Fade>
    </>
  );
};

export default ContentText;
