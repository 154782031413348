import React from 'react';
import { Link } from 'gatsby';

import styles from './LanguageSelect.module.scss';

interface IProps {
  language?: string;
  link?: string;
}

const LanguageSelect: React.FC<IProps> = ({ language = 'en', link = '/' }) => {
  return (
    <>
      <div className={styles.language__container}>
        <div className={styles.language__select}>
          <Link to={link}>
            <span className={styles.language__text}>{language}</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default LanguageSelect;
