import React, { ReactNode } from 'react';
import Fade from 'react-reveal/Fade';

// import styles from './Headline.module.scss';

interface IProps {
  children: ReactNode;
}

const Headline: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <Fade bottom delay={200} distance={'5rem'} duration={600} origin={'bottom'}>
        <div className="content">
          <div className="content__cell--12-12">
            <h1>{children}</h1>
            <div className="underline"></div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Headline;
