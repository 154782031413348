import { useState, useEffect } from 'react';

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (typeof window !== undefined) {
      const getDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;

        return {
          width,
          height,
        };
      };

      const handleResize = () => {
        setWindowDimensions(getDimensions());
      };

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowDimensions;
};
