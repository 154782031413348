import React, { ReactNode } from 'react';

import styles from './FactBox.module.scss';

interface IProps {
  children: ReactNode;
  className?: string;
}

const FactBox: React.FC<IProps> = ({ children, className = '' }) => {
  return <div className={`${styles.container} ${className}`}>{children}</div>;
};

export default FactBox;
