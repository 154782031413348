import React, { ReactNode } from 'react';
import Footer from '../Footer';
import Navigation from '../Navigation';
import MenuItem from '../../models/MenuItem.model';
import { appConfig } from '../../../../../egonoid.com/src/common/config';

const mainMenuDE = (): MenuItem[] => {
  const result: MenuItem[] = [];

  console.log(JSON.stringify(appConfig));

  result.push({
    name: 'Start',
    link: '/de#start',
  });

  result.push({
    name: 'Leistungen',
    link: '/de#services',
  });

  result.push({
    name: 'Team',
    link: '/de#team',
  });

  if (appConfig.enableBlogPosts) {
    result.push({
      name: 'Blog',
      link: '/blog/',
    });
  }

  result.push({
    name: 'Kontakt',
    link: '/de#contact',
  });

  return result;
};

const mainMenuEN = (): MenuItem[] => {
  const result: MenuItem[] = [];

  result.push({
    name: 'Start',
    link: '/#start',
  });

  result.push({
    name: 'Services',
    link: '/#services',
  });

  result.push({
    name: 'Team',
    link: '/#team',
  });

  if (appConfig.enableBlogPosts) {
    result.push({
      name: 'Blog',
      link: '/blog/',
    });
  }

  result.push({
    name: 'Contact',
    link: '/#contact',
  });

  return result;
};

const footerMenuDE = (): MenuItem[] => {
  return [
    {
      name: 'Kontakt',
      link: '/de#contact',
    },
    {
      name: 'Impressum',
      link: '/de/impressum',
    },
    {
      name: 'Datenschutz',
      link: '/de/datenschutz',
    },
  ];
};

const footerMenuEN = (): MenuItem[] => {
  return [
    {
      name: 'Contact',
      link: '/#contact',
    },
    {
      name: 'Imprint',
      link: '/imprint',
    },
    {
      name: 'Privacy',
      link: '/privacy',
    },
  ];
};

interface IProps {
  location: any;
  title: string;
  children: ReactNode;
  mainMenu?: MenuItem[];
  footerMenu?: MenuItem[];
  showLogo?: boolean;
  language?: string;
}

const Layout: React.FC<IProps> = ({
  children,
  mainMenu = [],
  footerMenu = [],
  showLogo = false,
  language = 'en',
}) => {
  switch (language) {
    case 'de':
      if (mainMenu.length === 0) {
        mainMenu = mainMenuDE();
      }
      if (footerMenu.length === 0) {
        footerMenu = footerMenuDE();
      }
      break;
    default:
      if (mainMenu.length === 0) {
        mainMenu = mainMenuEN();
      }
      if (footerMenu.length === 0) {
        footerMenu = footerMenuEN();
      }
  }

  return (
    <>
      <Navigation showLogo={showLogo} mainMenu={mainMenu} language={language} />
      <main>{children}</main>
      <Footer menu={footerMenu} />
    </>
  );
};

export default Layout;
